.information {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, .25);
  padding: 20px 40px;
  width: 300px;
}

.information__title {
  font-weight: bold;
}

.information__action__link {
  background-color:#2C5378;
  border-color:#2C5378;
  display: block;
  text-align: center;
}

.information__action__link:hover {
  background-color: #6FADF1;
  border-color:#6FADF1;
}

.information__icon {
  position: absolute;
  top: 20px;
  left: 10px;
  width: 16px;
  height: 16px;
}

.information__minimize {
  position: absolute;
  top: 20px;
  right: 10px;
  display: block;
  border: solid #BFBFBF;
  border-width: 0 2px 2px 0;
  font-size: 0;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}

.information > p:last-child {
  margin-bottom: 0;
}

.information--minimized {
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
  width: 16px;
  height: 16px;
  transition: background-color 300ms;
}

.information--minimized:hover {
  background-color: #2C5378;
}

.information--minimized .information__title,
.information--minimized .information__message,
.information--minimized .information__action,
.information--minimized .information__minimize {
  display: none;
}

.information--minimized .information__icon {
  position: static;
  left: auto;
  top: auto;
}

